import { useStorage } from '@vueuse/core';
import { computed } from 'vue';
import { STORAGE_KEY_WHATS_NEW_NOTIFICATION_BADGE } from '~/utilities/constants';

export function useWhatsNewNotification() {
  const hasViewedWhatsNew = useStorage(STORAGE_KEY_WHATS_NEW_NOTIFICATION_BADGE, false);
  const markWhatsNewAsViewed = () => (hasViewedWhatsNew.value = true);
  const conditionalHash = computed(() => (hasViewedWhatsNew.value ? '' : '#latest-updates'));

  return {
    hasViewedWhatsNew,
    markWhatsNewAsViewed,
    conditionalHash,
  };
}
