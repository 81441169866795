/**
 * Follows the default Tailwind breakpoints.
 * @see https://tailwindcss.com/docs/responsive-design
 */
export function checkBreakpoint(width: number): BreakpointType {
  if (width < 640) return 'xs';
  if (width < 768) return 'sm';
  if (width < 1024) return 'md';
  if (width < 1280) return 'lg';
  if (width < 1536) return 'xl';
  return '2xl';
}

export type BreakpointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
