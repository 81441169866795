<script setup lang="ts">
import { computed, onUnmounted, reactive, ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import SearchProjects from '~/components/SearchProjects.vue';
import EmptySearchResults from '~/components/EmptySearchResults.vue';
import type { Collection, Project } from '~/utilities/pyscript-api-models';
import { fuzzySearch } from '~/utilities/fuzzy-search';
import { useCollectionsStore } from '~/stores/collections-store';
import IconCheck from '~icons/ph/check-bold';
import IconClose from '~icons/mdi/close';
import IconAdd from '~icons/carbon/add-filled';

const props = defineProps<{
  show: boolean;
  project: Project;
}>();

const collectionsStore = useCollectionsStore();

const collectionsList = computed(() => {
  return collectionsStore.collections.toSorted((a: Collection, b: Collection) =>
    a.name.localeCompare(b.name),
  );
});

const shouldOpenCreateCollectionModal = ref(false);

const search = reactive({
  query: '',
  results: [] as Collection[],
});

function closeModal(isCreateCollectionFlow?: boolean) {
  collectionsStore.closeAddProjectToCollectionModal(Boolean(isCreateCollectionFlow));
  search.query = '';
}

function onSearch() {
  search.results = fuzzySearch<Collection>(search.query, collectionsList.value, {
    keys: ['name'],
  });
}

async function updateProjectsInCollection(collection: Collection) {
  if (collection.projects.includes(props.project.id)) {
    // Remove if it's already in the collection.
    await collectionsStore.updateProjectsInCollection(collection.id, [], [props.project.id]);
  } else {
    // Add if it's not in the collection.
    await collectionsStore.updateProjectsInCollection(collection.id, [props.project.id], []);
  }
}

function onCreateNewCollection() {
  shouldOpenCreateCollectionModal.value = true;
  closeModal(true);
}

onUnmounted(() => {
  if (shouldOpenCreateCollectionModal.value) {
    collectionsStore.openCollectionModal();
  }
});
</script>

<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" class="relative z-20" @close="closeModal">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25 dark:bg-opacity-40" />
      </TransitionChild>

      <div class="fixed inset-0">
        <div class="flex h-screen items-center justify-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="flex h-screen w-full items-center justify-center p-4">
              <DialogPanel
                class="flex max-h-full w-full max-w-md transform flex-col rounded-lg bg-white shadow-xl dark:bg-new-gray-800"
              >
                <DialogTitle
                  class="px-8 pb-3 pt-6 text-lg font-semibold text-gray-800 dark:text-gray-200"
                >
                  {{ $t('collections.add_project_to_collection_modal.title') }}
                </DialogTitle>

                <SearchProjects
                  v-model="search.query"
                  class="mx-5 mb-3"
                  :search-fn="onSearch"
                  :placeholder="
                    $t('collections.add_project_to_collection_modal.search_placeholder')
                  "
                />
                <button
                  type="button"
                  title="Close modal"
                  class="absolute right-5 top-5 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                  @click="() => closeModal()"
                >
                  <IconClose aria-hidden="true" />
                </button>

                <section class="custom-scrollbar overflow-y-auto px-6 pb-2 text-sm">
                  <!-- No search results -->
                  <EmptySearchResults
                    v-if="search.query && !search.results.length"
                    i18n-key="states.noun.collections"
                  />

                  <ul v-else>
                    <li
                      v-for="collection in search.query && search.results.length
                        ? search.results
                        : collectionsList"
                      :key="collection.id"
                      class="relative -mb-[1px]"
                    >
                      <button
                        class="group flex w-full items-center gap-2.5 rounded px-2 py-1.5 text-left hover:bg-new-gray-50 dark:hover:bg-new-gray-600"
                        @click="updateProjectsInCollection(collection)"
                      >
                        <div
                          :class="[
                            collection.projects.includes(project.id)
                              ? ''
                              : 'opacity-0 group-hover:opacity-20',
                          ]"
                        >
                          <IconCheck class="text-xs" />
                        </div>

                        <span class="truncate">{{ collection.name }}</span>

                        <span
                          class="ml-auto whitespace-nowrap text-xs text-new-gray-500 dark:text-new-gray-200"
                          >{{ $t('collections.project_count', collection.projects.length) }}</span
                        >
                      </button>

                      <hr
                        class="absolute bottom-0 left-1 right-1 -z-1 border-t-new-gray-50 dark:border-t-new-gray-600"
                      />
                    </li>

                    <li>
                      <button
                        class="flex w-full items-center gap-2 rounded p-2 text-left hover:bg-new-gray-50 dark:hover:bg-new-gray-600"
                        @click="onCreateNewCollection"
                      >
                        <IconAdd class="text-sm" />
                        {{ $t('collections.add_project_to_collection_modal.new_collection') }}
                      </button>
                    </li>
                  </ul>
                </section>
              </DialogPanel>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
