<script setup lang="ts">
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '~/stores/user-store';
import { useBadgesStore } from '~/stores/badges-store';
import pyscriptApi from '~/utilities/pyscript-api';

/**
 * OVERVIEW OF CASES TO CONSIDER:
 *
 * A) User IS logged in:
 * - If the user already has an active Founders plan, redirect to the dashboard
 * - If they DON'T have an active Founders plan, redirect to checkout
 *
 * B) User NOT logged in:
 * 1. Set localStorage.psdc_redirect with a value of the current path+query params
 * 2. Redirect the user to /registration
 * 3. When the user logs in they'll be redirected back to the original page they were on
 *    - I.e. `/purchase-founders-package?utm_campaign=pycon2023&utm_medium=lp&utm_source=marketo`
 */

const router = useRouter();
const userStore = useUserStore();
const badgesStore = useBadgesStore();

/**
 * If user not logged in, save the current route they're at in localStorage and
 * redirect them to the registration page. They'll be redirect back after login.
 */
if (!userStore.isLoggedIn) {
  localStorage.setItem('psdc_redirect', router.currentRoute.value.fullPath);
  setTimeout(() => router.push({ name: 'registration' }), 4000);
}

watch(
  () => badgesStore.hasFoundersBadge,
  (val) => {
    if (val === true) {
      setTimeout(() => router.push({ name: 'dashboard' }), 4000);
    }

    if (val === false) {
      setTimeout(() => pyscriptApi.checkoutFoundersPlan(), 1000);
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="flex h-screen w-screen flex-col items-center justify-center">
    <div class="flex max-w-lg flex-col items-center justify-center gap-4 px-4 text-center">
      <template v-if="userStore.isLoggedIn === false">
        <h2 class="whitespace-pre-wrap break-keep text-xl font-medium">
          Please register before purchasing the<br class="hidden lg:block" />
          Founders package.
        </h2>
        <p>Redirecting you to registration...</p>
      </template>

      <template v-else-if="userStore.isLoggedIn && badgesStore.hasFoundersBadge === true">
        <h2 class="text-xl font-medium">
          Oops! You've already purchased the<br class="hidden lg:block" />
          Founders Package.
        </h2>
        <p>We appreciate your support.</p>
        <p>Redirecting you to your dashboard...</p>
      </template>

      <template v-else-if="userStore.isLoggedIn && badgesStore.hasFoundersBadge === false">
        <h2 class="text-xl font-medium">
          Redirecting you to purchase the<br class="hidden lg:block" />
          Founders Package...
        </h2>
      </template>

      <div class="dot-flashing" />
    </div>
  </div>
</template>
