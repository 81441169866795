<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <main class="container-spacing mx-auto mt-6 max-w-4xl">
    <h1 class="mb-8 text-2xl font-semibold md:text-4xl">{{ t('about_page.heading') }}</h1>

    <section class="space-y-6 md:text-lg">
      <i18n-t keypath="about_page.body_p1" tag="p">
        <template #link>
          <a
            href="https://www.anaconda.com/blog/pyscript-python-in-the-browser"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            title="PyScript"
          >
            PyScript</a
          >
        </template>
      </i18n-t>

      <i18n-t keypath="about_page.body_p2" tag="p">
        <template #link>
          <a
            href="https://www.anaconda.com/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            title="Anaconda"
          >
            Anaconda</a
          >
        </template>
      </i18n-t>

      <i18n-t keypath="about_page.body_p3" tag="p">
        <template #link1>
          <a
            href="https://anaconda.cloud/s/pyscript"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            title="Anaconda Nucleus"
          >
            Anaconda Nucleus
          </a>
        </template>

        <template #twitter_handle>@pyscript_dev</template>

        <template #link2>
          <a
            href="https://twitter.com/pyscript_dev?lang=en"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            title="Twitter"
          >
            Twitter
          </a>
        </template>
      </i18n-t>
    </section>
  </main>
</template>
