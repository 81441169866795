/**
 * Takes an object and translates it to a query string.
 *
 * If an property value is `undefined` or `null`, it will be omitted from the
 * query string. Empty strings (`''`) are kept intact.
 */
export function objectToQueryParams(obj: Record<string, any>) {
  const params = Object.entries(obj).filter(
    ([_key, value]) => value !== undefined && value !== null && value !== '',
  );

  return new URLSearchParams(params).toString();
}
