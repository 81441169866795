import { defineStore } from 'pinia';
import { useUserStore } from './user-store';
import type { Collection, Project } from '~/utilities/pyscript-api-models';
import pyscriptApi from '~/utilities/pyscript-api';
import { useSortPreference } from '~/composables/sort-preference';

interface State {
  isInitialized: boolean;
  collections: Collection[];

  /** Shows a modal to create or edit a Collection. */
  showCollectionModal: boolean;
  /** Used if a collection is being edited. */
  collectionBeingEdited: Collection | null;

  /** Shows a modal to add a single project to a Collection. */
  showAddProjectToCollectionModal: boolean;
  /** Used when adding a single project to a Collection. */
  projectToBeAdded: Project | null;
}

/** Store for the logged-in user's collections. */
export const useCollectionsStore = defineStore('collections', {
  state: (): State => ({
    isInitialized: false,
    collections: [],
    showCollectionModal: false,
    collectionBeingEdited: null,
    showAddProjectToCollectionModal: false,
    projectToBeAdded: null,
  }),

  actions: {
    /** Only be called once on page load via the `src/main.ts` file. */
    async init() {
      await this.fetchCollections();
      this.isInitialized = true;
    },

    /** Fetch the logged-in user's collections. */
    async fetchCollections() {
      const userStore = useUserStore();
      const { savedSort } = useSortPreference();

      this.collections = await pyscriptApi.listCollections({
        user: userStore.user!.id,
        sortBy: savedSort.value.sortBy,
        sortOrder: savedSort.value.sortOrder,
      });

      return this.collections;
    },

    /** Fetch Collections search results for the logged-in user. */
    async fetchSearchResults(query?: string) {
      const userStore = useUserStore();
      const { savedSort } = useSortPreference();

      return pyscriptApi.listCollections({
        q: query,
        user: userStore.user!.id,
        sortBy: savedSort.value.sortBy,
        sortOrder: savedSort.value.sortOrder,
      });
    },

    /** Create a collection. */
    async createCollection(name: string, description: string, privacy: boolean) {
      const collection = await pyscriptApi.createCollection(name, description, privacy);
      await this.fetchCollections();

      // If a project is being added to a collection that's being created, add it now.
      if (this.projectToBeAdded) {
        await this.updateProjectsInCollection(collection.id, [this.projectToBeAdded.id], []);
        this.projectToBeAdded = null;
      }
      return collection;
    },

    /** Delete a collection. */
    async deleteCollection(collectionId: string) {
      // eslint-disable-next-line no-alert
      const confirmResult = confirm(
        `Are you sure you want to delete this collection? This action cannot be undone.`,
      );

      if (!confirmResult) return;

      await pyscriptApi.deleteCollection(collectionId);
      await this.fetchCollections();
    },

    /** Update a collections details. */
    async updateCollection(
      collectionId: string,
      name: string,
      description: string,
      privacy: boolean,
    ) {
      await pyscriptApi.updateCollection(collectionId, name, description, privacy);
      await this.fetchCollections();
    },

    /** Update what projects are in a collection. */
    async updateProjectsInCollection(
      collectionId: string,
      projectIdsToAdd: string[],
      projectIdsToRemove: string[],
    ) {
      await Promise.allSettled([
        pyscriptApi.addProjectsToCollection(collectionId, projectIdsToAdd),
        pyscriptApi.removeProjectsFromCollection(collectionId, projectIdsToRemove),
      ]);

      // Refetch collectinos to get updated number of projects in each collection.
      this.fetchCollections();
    },

    /** Show the collection modal to update its details. */
    openCollectionModal(collection?: Collection) {
      if (collection) {
        this.collectionBeingEdited = collection;
      }
      this.showCollectionModal = true;
    },

    /** Close the collection modal. */
    async closeCollectionModal() {
      this.showCollectionModal = false;
      this.collectionBeingEdited = null;
    },

    openAddProjectToCollectionModal(project: Project) {
      this.projectToBeAdded = project;
      this.showAddProjectToCollectionModal = true;
    },

    closeAddProjectToCollectionModal(isCreateCollectionFlow?: boolean) {
      // If in a create collection flow, don't reset the project to be added.
      if (!isCreateCollectionFlow) {
        this.projectToBeAdded = null;
      }
      this.showAddProjectToCollectionModal = false;
    },
  },
});
