<script setup lang="ts">
import { computed, onBeforeMount, reactive } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useRouter } from 'vue-router';
import { reset } from '@formkit/vue';
import { useCollectionsStore } from '~/stores/collections-store';

const collectionsStore = useCollectionsStore();
const router = useRouter();

const formState = reactive({
  name: '',
  description: '',
  privacy: 'public',
});

onBeforeMount(() => {
  if (collectionsStore.collectionBeingEdited) {
    formState.name = collectionsStore.collectionBeingEdited.name;
    formState.description = collectionsStore.collectionBeingEdited.description;
    formState.privacy = collectionsStore.collectionBeingEdited.private ? 'private' : 'public';
  }
});

/** Determines if a collection is being edited or created. */
const isEditingCollection = computed(() => Boolean(collectionsStore.collectionBeingEdited?.id));

function closeModal() {
  collectionsStore.closeCollectionModal();
}

function onCancel() {
  closeModal();
  reset('collection-form');
  /**
   * Reset the project to be added as a precaution in case it was set while
   * creating a collection and the user cancelled.
   */
  collectionsStore.projectToBeAdded = null;
}

async function submitHandler(data: any) {
  const privacy = data.privacy === 'private';

  if (isEditingCollection.value) {
    await collectionsStore.updateCollection(
      collectionsStore.collectionBeingEdited!.id,
      data.name,
      data.description,
      privacy,
    );
  } else {
    const collection = await collectionsStore.createCollection(
      data.name,
      data.description,
      privacy,
    );
    router.push({
      name: 'collection-projects',
      params: {
        collectionId: collection.id,
        usernameOrUserId: collection.username || collection.user_id,
      },
    });
  }
  await collectionsStore.fetchCollections();
  closeModal();
}
</script>

<template>
  <TransitionRoot appear :show="collectionsStore.showCollectionModal" as="template">
    <Dialog as="div" class="relative z-20" @close="closeModal">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25 dark:bg-opacity-40" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="flex min-h-screen w-full items-center justify-center p-4">
            <div
              class="w-full max-w-md transform overflow-hidden rounded-lg bg-white px-7 py-6 shadow-xl transition-all dark:bg-new-gray-800"
            >
              <DialogTitle as="div" class="mb-2 flex items-center justify-between">
                <h2 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
                  {{
                    isEditingCollection
                      ? $t('collections.edit_collection_heading')
                      : $t('collections.new_collection_heading')
                  }}
                </h2>
              </DialogTitle>

              <FormKit
                id="collection-form"
                v-slot="{ disabled, state: { dirty } }"
                type="form"
                :actions="false"
                @submit="submitHandler"
              >
                <FormKit
                  v-model="formState.name"
                  data-1p-ignore
                  type="text"
                  name="name"
                  label="Collection Name"
                  validation="required:trim|length:3,50"
                  placeholder="Enter collection name"
                  :classes="{
                    outer: 'flex-1 max-w-xs',
                    inner: 'ring-new-gray-100 dark:ring-new-gray-800',
                  }"
                />

                <FormKit
                  v-model="formState.description"
                  type="textarea"
                  name="description"
                  label="Description"
                  placeholder="Enter collection description"
                  :classes="{
                    outer: 'flex-1 max-w-xs',
                    inner: 'ring-new-gray-100 dark:ring-new-gray-800',
                  }"
                />

                <FormKit
                  v-model="formState.privacy"
                  type="radio"
                  name="privacy"
                  label="Privacy"
                  :options="[
                    { label: 'Public', value: 'public' },
                    { label: 'Private', value: 'private' },
                  ]"
                  value="public"
                  :classes="{
                    label: 'text-new-gray-700 dark:text-new-gray-200',
                  }"
                />

                <footer class="flex items-center justify-between">
                  <button
                    class="btn --space-sm --white ml-auto mr-4 h-8 text-xs"
                    type="button"
                    :disabled="Boolean(disabled)"
                    @click="onCancel"
                  >
                    {{ $t('collections.buttons.cancel') }}
                  </button>

                  <FormKit
                    type="submit"
                    :classes="{ outer: '$remove:mb-4', wrapper: '$remove:mb-1' }"
                    :label="
                      isEditingCollection
                        ? $t('collections.buttons.save')
                        : $t('collections.buttons.create')
                    "
                    :disabled="Boolean(disabled) || !dirty"
                  />
                </footer>
              </FormKit>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
