import { createVfm } from 'vue-final-modal';
import type { UserModule } from './user-module-type';

/**
 * Add vue-final-modal
 * @see https://vue-final-modal.org/
 */
export const install: UserModule = ({ app }) => {
  app.use(createVfm());
};
