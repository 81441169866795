import { defineStore } from 'pinia';
import pyscriptApi from '~/utilities/pyscript-api';
import type { Project, Whoami } from '~/utilities/pyscript-api-models';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null as Whoami | null,
  }),

  getters: {
    canUseApiProxies(): boolean {
      return this.quotas['api-proxies-quota']?.max !== 0;
    },

    canUsePrivateProjects(): boolean {
      return this.quotas['private-projects-quota']?.max !== 0;
    },

    canUseAssistant(): boolean {
      return this.permits.includes('use-assistant');
    },

    isLoggedIn(state) {
      return Boolean(state.user);
    },

    isDeveloper(state) {
      return state.user?.tier_id === 'developer';
    },

    permits(state) {
      return state.user?.permit_ids || [];
    },

    quotas(state) {
      return state.user?.quotas || {};
    },

    usernameOrId(state) {
      return state.user?.username || state.user?.id || '';
    },
  },

  actions: {
    async fetchUser() {
      const user = await pyscriptApi.whoami();

      if (user && window.heap) {
        // Note: heap is being added in the heap module.
        // we are passing the unique user id to heap to
        // merge anonymous data if it exists
        window.heap.identify(user.id);
      }

      this.user = user;
      return user;
    },

    /** Checks if the logged in user is the owner of a project. */
    isProjectOwner(project: Project): boolean {
      if (!this.isLoggedIn) {
        return false;
      }
      return this.user?.id === project.user_id;
    },
  },
});
