import { defineStore } from 'pinia';
import pyscriptApi from '~/utilities/pyscript-api';
import type { Favorite, Project } from '~/utilities/pyscript-api-models';
import { sortByDate } from '~/utilities/sort-items';

interface State {
  isInitialized: boolean;
  favorites: Favorite[];
}

/**
 * Store for managing the user's favorites. If the user is logged in,
 * the store is initialized via the `src/main.ts` on page load.
 */
export const useFavoritesStore = defineStore('favorites', {
  state: (): State => ({
    /**
     * Determines if the store has initialized and fetched the user's favorites
     * on page load. Primarily used to prevent having to `await` in the
     * `src/main.ts` file.
     */
    isInitialized: false,

    /** List of the user's favorite projects. Doesn't include project data. */
    favorites: [],
  }),

  getters: {
    /**
     * Creates a Set of favorites project IDs, making it easy and performant
     * to check if a project is a favorite.
     */
    favoritesProjectIds(state) {
      return new Set(state.favorites.map((favorite) => favorite.project_id));
    },
  },

  actions: {
    /** Only be called once on page load via the `src/main.ts` file. */
    async init() {
      await this.fetchFavorites();

      // Mark the store as initialized, indicating the store is ready for use.
      this.isInitialized = true;
    },

    /** Fetch the logged-in user's favorite projects. */
    async fetchFavorites() {
      // Fetch favorites and sort based on the date they were favorited.
      const unsortedFavorites = await pyscriptApi.listFavorites();
      this.favorites = sortByDate(unsortedFavorites, 'created_at');
    },

    /** Add a favorite. */
    async addFavorite(project: Project) {
      const { id: projectId, user_id: userId } = project;

      // Optimistic UI update.
      if (!this.favoritesProjectIds.has(projectId)) {
        this.favorites.push({
          user_id: userId,
          project_id: projectId,
          created_at: '',
        });
      }

      await pyscriptApi.addFavorite(projectId);
    },

    /** Remove a favorite. */
    async removeFavorite(projectId: string) {
      // Optimistic UI update.
      this.favorites = this.favorites.filter((favorite) => favorite.project_id !== projectId);

      await pyscriptApi.removeFavorite(projectId);
    },

    /** Determine if a project is a favorite project. */
    isFavorite(projectId: string) {
      return this.favoritesProjectIds.has(projectId);
    },
  },
});
