import type { ParsedPath } from '~/utilities/path.types';

const startsWithDot = (path: string) => path.startsWith('.');

function isValidURL(url: string): boolean {
  let toURL;
  try {
    toURL = new URL(url);
  } catch (_) {
    return false;
  }
  return toURL.protocol === 'http:' || toURL.protocol === 'https:';
}

/**
 * This `path` module provides utilities for working with file and directory paths.
 * Simply `import path from '~/utilities/path';`.
 *
 * Please see unit tests for more examples.
 *
 * Inspired by NodeJS's path module.
 * @see https://nodejs.org/docs/v10.3.0/api/path.html
 */
export default {
  /**
   * Returns the last portion of a path.
   *
   * For example: path.basename('path/to/some/file.txt')
   * Returns: 'file.txt'
   */
  basename(path: string) {
    if (!path) return '';
    return path.split('/').at(-1) || '';
  },

  /**
   * Returns the extension name.
   *
   * For example: path.extname('path/to/some/file.txt')
   * Returns: '.txt'
   */
  extname(path: string): string {
    let filename = this.basename(path);

    /**
     * Removing first dot so we don't use it in our regex.
     * For example, .env is just a file named .env, it has no extension.
     * However, a file named .env.dev would result in .dev as the extension.
     */
    if (startsWithDot(filename)) {
      filename = [...filename].slice(1).join('');
    }

    const regex = /\.[0-9a-z]+$/i;
    const m = filename.match(regex);
    return m && m.length ? m[0] : '';
  },

  /**
   * Returns the directory name of a file.
   *
   * For example: path.dirname('path/to/some/file.txt)
   * Returns: 'path/to/some'
   */
  dirname(path: string): string {
    if (!path) return '';
    return path.split('/').slice(0, -1).join('/');
  },

  /**
   * Parses a path.
   *
   * For example: path.parse('home/user/dir/file.txt');
   * Returns: {
   *   dir: 'home/user/dir',
   *   base: 'file.txt',
   *   ext: '.txt',
   *   name: 'file'
   * }
   */
  parse(path: string): ParsedPath {
    const result = { dir: '', base: '', ext: '', name: '' };
    if (!path) return result;

    const filename = this.basename(path);

    result.base = this.basename(path);
    result.dir = this.dirname(path);
    result.ext = this.extname(path);

    const lastDotIndex = startsWithDot(filename) ? undefined : filename.lastIndexOf('.');
    result.name = Array.from(filename).slice(0, lastDotIndex).join('');

    return result;
  },

  /**
   * Adds a URL to the front of a path.
   * Useful for creating the Amazon S3 URL for when we GET a file's contents from S3.
   *
   * For example: path.toAbsoluteURL('path/to/some/file.txt, 'https://example.com/')
   * Returns: 'https://example.com/path/to/some/file.txt'
   */
  toAbsoluteURL(path: string, url: string = import.meta.env.PUBLIC_APPS_HOST): string | undefined {
    if (path === undefined || path === null) {
      throw new Error('The "path" argument cannot be undefined or null');
    }

    if (url === '') {
      throw new Error('The "url" argument cannot be an empty string');
    }

    // If the "url" arg isn't a valid URL
    if (!isValidURL(url)) {
      throw new Error('The "url" argument must be a valid URL');
    }

    // If the "path" arg is already a URL
    if (isValidURL(path)) return path;

    // Remove starting forward slash from "path" arg
    if (path.startsWith('/')) {
      path = [...path].slice(1).join('');
    }

    // Remove ending forward slash from "url" arg
    if (url.endsWith('/')) {
      url = [...url].slice(0, -1).join('');
    }

    return `${url}/${path}`;
  },

  /**
   * Adds a forward slash to the beginning of the path.
   *
   * For example: path.toAbsolutePath('path/to/some/file.txt)
   * Returns: '/path/to/some/file.txt'
   */
  toAbsolutePath(path: string): string {
    if (!path) return '';
    if (path === '.') return '.';
    if (this.isAbsolutePath(path)) return path;

    return `/${path}`;
  },

  /**
   * Determines if the path starts with a leading forward slash.
   */
  isAbsolutePath(path: string): boolean {
    if (!path) return false;
    if (path === '.') return false;
    return path.startsWith('/');
  },

  /**
   * Takes path segments and returns the joined path.
   *
   * For example: path.join('/path', '/to', '/some/', '/file.txt')
   * Returns: '/path/to/some/file.txt
   */
  join(...pathSegments: string[]): string {
    // Remove falsy values and join values into one string
    const joined = pathSegments.filter(Boolean).join('/');
    return joined.replace(/\/+/g, '/'); // Replace consecutive slashes with a single slash
  },
};
