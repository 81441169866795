import type { IFuseOptions } from 'fuse.js';
import Fuse from 'fuse.js';

export function fuzzySearch<T>(
  searchQuery: string,
  objectsToSearch: T[],
  options?: IFuseOptions<T>,
): T[] {
  const fuse = new Fuse(objectsToSearch, {
    keys: ['name', 'description', 'tags'], // search keys in objects
    includeScore: true, // to sort results by relevance
    threshold: 0.3,
    ...options, // allow to pass additional Fuse.js options
  });

  const searchResults = fuse.search(searchQuery);

  return searchResults.map((result) => result.item);
}
