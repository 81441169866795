general:
  last_edited: Edited
  search_placeholder: Filter by title, tag, or description
  search_collections_placeholder: Filter by title or description
  preview: Preview
  files: Files

states:
  no_items_created:
    title: No {noun} Yet
    description: Create a new {noun} to get started.
    button_text: New {noun}
  no_search_results: No {noun} match your search query
  noun:
    projects: Project | Projects
    collections: Collection | Collections
    favorites: Favorite |Favorites
    examples: Example | Examples

errors:
  generic: Something went wrong. Please try again.

links:
  help: Help
  privacy_policy: Privacy Policy
  legal: Legal
  settings: Settings
  dashboard: Dashboard
  profile: Profile
  whats_new: What's New
  docs: Documentation
  favorites: Favorites

tooltips:
  fork: Create a copy of this project
  fork_logged_out: Sign in to copy this project
  grid_view: Grid view
  list_view: List view
  sort_options: Sort options

theme:
  dark_mode: Dark Mode

editor:
  read_only: Read only.
  read_only_version_uneditable: Versions are not editable.
  clone_to_edit: Clone this project to edit.

buttons:
  add_tag: + Add a tag
  add_to_collection: Add to Collection
  remove_from_collection: Remove from Collection
  creating_project: Creating
  delete: Delete
  edit: Edit
  fork_project: Clone Project
  fork: Clone
  forking: Cloning...
  hide_files: Hide Files
  hide_preview: Hide Preview
  new_file: New File
  new_folder: New Folder
  new_project: New Project
  preview_markdown: Preview
  project_settings: Settings
  report_abuse: Report Abuse
  rename: Rename
  run: Run
  save: Save
  share: Share
  show_files: Show Files
  show_preview: Show Preview
  sign_in: Sign In
  sign_out: Sign Out
  sign_up: Sign Up
  support: Support
  upload_file: Upload File
  upload_folder: Upload Folder
  versions: Versions
  view_code: View Code
  view_site: View Site
  view_versions: View Versions

about_page:
  heading: About PyScript.com
  body_p1: >-
    At PyCon US 2022, Anaconda CEO Peter Wang announced the launch of {link}, a
    framework that empowers users to create rich applications in the browser
    using Python alongside JavaScript and HTML. Now, Anaconda is excited to
    unveil PyScript.com, a service that allows users to easily create, deploy,
    share, and store PyScript apps and plugins. PyScript.com functionality
    currently supports cron job management, webhook creation, running code on
    GPUs, and more! This offering effectively marks a change in the way users
    perform data science and takes a significant step towards democratizing data
    and programming.
  body_p2: >-
    PyScript and PyScript.com come from {link}, the world's most popular data
    science platform with more than 30 million users. Anaconda pioneered the use
    of Python for data science, champions its vibrant community, and continues
    to steward open-source projects that make tomorrow's innovations possible.
    Anaconda's enterprise-grade tools are the leading solution for securing and
    managing commercial uses of Python, and enable corporate, research, and
    academic institutions around the world to harness the power of open-source
    for competitive advantage, groundbreaking research, and building a smarter,
    better world.
  body_p3: >-
    Visit {link1} for additional PyScript content, and follow {twitter_handle}
    on {link2} to stay up to date with relevant news.

profile:
  heading: Profile
  projects_title: Projects
  collections_title: Collections
  no_projects:
    non_owner_text: It looks like this user doesn't have any Projects yet.
  no_collections:
    non_owner_text: It looks like this user doesn't have any Collections yet.

dashboard:
  heading: Dashboard
  tabs:
    projects: My Projects
    collections: Collections
    examples: Examples

favorites:
  page_heading: Favorites
  no_favorites:
    description: Add some Favorites by clicking the star icon on Projects to get started

collections:
  project_count: No Projects | {count} Project | { count } Projects
  buttons:
    save: Save
    create: Create
    cancel: Cancel
    edit_projects: Edit Projects
    add_projects: Add Projects
  private_collection: Private Collection
  new_collection_heading: New Collection
  edit_collection_heading: Edit Collection
  collection_view_heading: "Collection: {noun}"
  edit_projects_in_name_collection: Edit Projects in “{noun}” Collection
  search_placeholder: Filter projects by name
  projects_in_collection:
    no_projects:
      description: It looks like there are no Projects in this Collection.
      button: Add Projects
  add_project_to_collection_modal:
    title: Add to Collection
    search_placeholder: Filter Collections by name
    new_collection: Add to a new Collection
    no_projects: You have no Projects to add to this Collection.

project_settings:
  tabs:
    details: Details
    versions: Versions
    stats: Stats
    api_proxies: API Proxies
    permissions: Permissions
    channels: Channels
  api_proxies:
    heading: Allowed API Proxies
    description: Select the API Proxies to allow in this project.
    success_message: Changes saved
    submit: Save
  channels:
    heading: Allowed Channels
    description: Select the Channels to allow in this project.
    success_message: Changes saved
    submit: Save
  versions:
    actions: Actions
    create_version: Create Version
    created: Created
    default: Default
    delete: Delete
    delete_disabled:
      latest: Cannot delete latest.
      default_version: Cannot delete this version as it is the default for the project.
      current_route: Cannot delete this version as it is currently being viewed.
    last_edited: Last Edited
    published: Published
    tooltip_info_default_1: >-
      Designates the default version users will see when visiting the project's
      site.
    tooltip_info_default_2: For example, if a user were to visit
    tooltip_info_default_3: the user will see the version set as the default.
    version: Version

account_settings:
  page_title: Account Settings
  secrets:
    create_secret_submit_btn: Create Secret
    update_secret_submit_btn: Save Changes

editor_settings:
  modal_title: Editor Settings
  font_size_label: Font Size
  font_size_help: Change the editor font size in pixels.
  theme_label: Theme
  theme_help: Specifies the editor theme.
  wrapping_label: Line Wrap
  wrapping_help: Controls if lines should wrap.
