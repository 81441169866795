<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import IconSearch from '~icons/carbon/search';

const props = defineProps<{
  searchFn?: (event?: Event) => void;
  modelValue: string;
  placeholder?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string): void;
}>();

const { t } = useI18n();

function onInput(event: Event) {
  if (event.target instanceof HTMLInputElement) {
    emit('update:modelValue', event.target.value);
  }

  props.searchFn && props.searchFn(event);
}
</script>

<template>
  <div class="relative">
    <IconSearch
      class="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 text-sm text-new-gray-600 dark:text-white/50"
      aria-hidden="true"
    />

    <input
      id="posts-search"
      :value="modelValue"
      type="search"
      :placeholder="placeholder || `${t('general.search_placeholder')}...`"
      autocomplete="off"
      autocapitalize="off"
      autocorrect="off"
      spellcheck="false"
      aria-label="Search your projects"
      class="w-full rounded-full border border-new-gray-100 bg-white py-1.5 pl-9 pr-3 focus:border-new-gray-600 focus:bg-white focus:outline-none dark:border-new-gray-600 dark:bg-gray-675 dark:focus:border-new-gray-300 md:text-sm"
      @input="onInput"
    />
  </div>
</template>

<style scoped lang="postcss"></style>
