/**
 * Sort an array of objects by date using a property containing a date value.
 * This returns a new array, it won't mutate the original array.
 */
export function sortByDate<T extends object>(arr: T[], key: keyof T): T[] {
  return arr.slice().sort((a, b) => {
    return new Date(b[key] as string).getTime() - new Date(a[key] as string).getTime();
  });
}

/**
 * Sort an array of objects alphabetically in ascending order using a property
 * containing a string value. This returns a new array, it won't mutate
 * the original array.
 */
export function sortAtoZ<T extends object>(arr: T[], key: keyof T): T[] {
  return arr.slice().sort((a, b) => (a[key] > b[key] ? 1 : -1));
}

/**
 * Sort an array of objects alphabetically in descending order using a property
 * containing a string value. This returns a new array, it won't mutate
 * the original array.
 */
export function sortZtoA<T extends object>(arr: T[], key: keyof T): T[] {
  return arr.slice().sort((a, b) => (b[key] > a[key] ? 1 : -1));
}
