import { useStorage } from '@vueuse/core';
import { STORAGE_KEY_PROJECTS_SORT } from '~/utilities/constants';
import type { ListProjectsSortBy, ListProjectsSortOrder } from '~/utilities/pyscript-api-models';

export interface SortOptionValue {
  sortBy: ListProjectsSortBy;
  sortOrder: ListProjectsSortOrder;
}

export interface SortOption {
  id?: string;
  text?: string;
  value?: SortOptionValue;
}

const sortOptions: SortOption[] = [
  {
    text: 'Title (A-Z)',
    value: {
      sortBy: 'name',
      sortOrder: 'asc',
    },
  },
  {
    text: 'Title (Z-A)',
    value: {
      sortBy: 'name',
      sortOrder: 'desc',
    },
  },
  { id: 'spacer' },
  {
    text: 'Modified (new to old)',
    value: {
      sortBy: 'updated_at',
      sortOrder: 'desc',
    },
  },
  {
    text: 'Modified (old to new)',
    value: {
      sortBy: 'updated_at',
      sortOrder: 'asc',
    },
  },
  { id: 'spacer' },
  {
    text: 'Created (new to old)',
    value: {
      sortBy: 'created_at',
      sortOrder: 'desc',
    },
  },
  {
    text: 'Created (old to new)',
    value: {
      sortBy: 'created_at',
      sortOrder: 'asc',
    },
  },
];

export function useSortPreference() {
  const defaultSort: SortOptionValue = {
    sortBy: 'updated_at',
    sortOrder: 'desc',
  };
  const savedSort = useStorage<SortOptionValue>(
    STORAGE_KEY_PROJECTS_SORT,
    defaultSort,
    localStorage,
    {
      // Doing this because the value used to be a string but now it's JSON. This
      // will update the value to the new format if it's still the old format.
      onError: () => localStorage.setItem(STORAGE_KEY_PROJECTS_SORT, JSON.stringify(defaultSort)),
    },
  );
  const updateSort = (sort: SortOptionValue) => (savedSort.value = sort);

  return {
    savedSort,
    sortOptions,
    updateSort,
  };
}
