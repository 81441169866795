import FloatingVue from 'floating-vue';
import type { UserModule } from './user-module-type';

/**
 * Floating Vue tooltip plugin.
 * @see https://floating-vue.starpad.dev/
 *
 * To configure the default options
 * @see https://floating-vue.starpad.dev/guide/config
 */
export const install: UserModule = ({ app }) => {
  FloatingVue.options.themes.tooltip.triggers = ['hover', 'touch'];

  app.use(FloatingVue, {
    distance: 8,
  });
};
