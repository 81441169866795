<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useCollectionsStore } from '~/stores/collections-store';
import AddProjectToCollectionModal from '~/components/collections/AddProjectToCollectionModal.vue';
import CollectionFormModal from '~/components/collections/CollectionFormModal.vue';

const hasDarkPreference =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const collectionsStore = useCollectionsStore();

useHead({
  titleTemplate: (title) => (title ? `PyScript | ${title}` : `PyScript`),
  meta: [
    {
      name: 'description',
      content: '', // TODO: Add a meta description
    },
    {
      name: 'theme-color',
      content: hasDarkPreference ? '#00aba9' : '#ffffff',
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: '/images/favicon.svg',
    },
  ],
});
</script>

<template>
  <RouterView />

  <AddProjectToCollectionModal
    v-if="collectionsStore.showAddProjectToCollectionModal && collectionsStore.projectToBeAdded"
    :show="collectionsStore.showAddProjectToCollectionModal"
    :project="collectionsStore.projectToBeAdded"
  />

  <CollectionFormModal v-if="collectionsStore.showCollectionModal" />
</template>
