/**
 * Converts camelCase to snake_case.
 *
 * @example
 * type result1 = SnakeCase<"hello">; // => "hello"
 * type result2 = SnakeCase<"userName">; // => "user_name"
 * type result3 = SnakeCase<"getElementById">; // => "get_element_by_id"
 */
export type SnakeCase<T> = T extends `${infer A}${infer R}`
  ? Uppercase<A> extends A
    ? `_${Lowercase<A>}${SnakeCase<R>}`
    : `${A}${SnakeCase<R>}`
  : '';

/**
 * Converts an object's keys from camelCase to snake_case.
 *
 * @example
 * type B = SnakeCaseKeys<{
 *   hello: string;
 *   userName: string;
 *   getElementById: string;
 * }>;
 *
 * // => {
 * //  hello: string;
 * //  user_name: string;
 * //  get_element_by_id: string;
 * // }
 */
export type SnakeCaseKeys<T extends object> = {
  [key in keyof T as key extends string ? SnakeCase<key> : key]: T[key] extends object
    ? SnakeCase<T[key]>
    : T[key];
};

export function toSnakeCase<T>(key: string): SnakeCase<T> {
  return key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`) as SnakeCase<T>;
}

export function toSnakeCaseKeys<T extends object>(obj: T): SnakeCaseKeys<T> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      toSnakeCase(key),
      typeof value === 'object' ? toSnakeCaseKeys(value) : value,
    ]),
  ) as SnakeCaseKeys<T>;
}
