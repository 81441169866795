/**
 * Sets the current page's path (including the hash) in `localStorage` to allow
 * the router to return the user to this path when they return.
 *
 * @example
 * - The user's session cookie expires and we want to return them to the page they were.
 * - While not logged in, the user is viewing another user's project and wants to fork
 *   it but has to log in first.
 * - User is on a landing page like the /join page and tries to purchase Founders.
 *   After they register, they'll be sent back to /join page.
 */
export async function setPsdcRedirect() {
  const currentPath = `${window.location.pathname}${window.location.hash}`;
  localStorage.setItem('psdc_redirect', currentPath);
}
