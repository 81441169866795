import { defineStore } from 'pinia';
import pyscriptApi from '~/utilities/pyscript-api';
import type { UserBadge } from '~/utilities/pyscript-api-models';

export const useBadgesStore = defineStore('badges', {
  state: () => ({
    userBadges: null as UserBadge[] | null,
  }),

  getters: {
    hasFoundersBadge(state): boolean {
      return Boolean(state.userBadges?.some((item) => item.badge.id === 'pyscript.founder'));
    },
  },

  actions: {
    async fetchBadges(user: string) {
      this.userBadges = await pyscriptApi.listUserBadges(user);
    },
  },
});
